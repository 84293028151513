.contact-address {
    border-right: 1px solid #e0e0e0 !important;
    justify-content: center;
}

.contact-address ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.list-inline{
    flex-wrap: nowrap !important;
}
  