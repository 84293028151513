.circular-icon {
    width: 30px;
    /* Adjust size as needed */
    height: 30px;
    /* Adjust size as needed */
    border-radius: 50%;
    /* Makes the div circular */
    background-color: #f0f0f0;
    /* Background color of the circular div */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* Adjust margin as needed */
}

.circular-check {
    background-color: #b3ffb3;
}

.icon-feather-check {
    color: #22c55e;
}

.circular-exc {
    background-color: #ffffb3;
}

.icon-exc {
    color: #dcdc00;
}

.circular-cross {
    background-color: #ffb3b3
}

.icon-feather-x {
    color: #ff0000;
}

.circular-icon i {
    font-size: 22px;
    font-style: normal;
}

/* CSS */
.container1 {
    display: flex;
    align-items: center;
    /* Align items vertically */
}

.circular-icon,
.message {
    margin-right: 10px;
    /* Adjust spacing between icon and text */
}