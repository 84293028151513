.attachment-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachment-box .view-attachment:before {
    content: "\e95c";
    font-family: "Feather-Icons";
}

.attachment-box .view-attachment {
    position: absolute;
    bottom: 10px;
    right: 45px;
    color: #fff;
    background-color: #2a41e8;
    box-shadow: 0 3px 8px rgba(234, 65, 81, 0.15);
    height: 28px;
    width: 28px;
    line-height: 28px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;
    opacity: 0;
    transform: translateY(3px);

}

.attachment-box:hover .view-attachment {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .profile-details {
        flex-direction: column;
    }

    .profile-details .col-auto:first-child {
        align-self: center;
    }
}
